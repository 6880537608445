<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import rem from "./common/rem.js";
export default {
  data() {
    return {};
  },
  created() {
    rem();
  },
};
</script>
<style scoped>
@import url("~assets/css/base.css");
</style>
