export default function() {
    var doc = document.documentElement,
        resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
        recalc = function() {
            var clientWidth = doc.clientWidth;
            if (!clientWidth) {
                return;
            }
            if (clientWidth >= 750) {
                doc.style.fontSize = "100px";
            } else {
                doc.style.fontSize = 100 * (clientWidth / 750) + "px";
            }
        };
    if (!document.addEventListener) {
        return
    };
    window.addEventListener(resizeEvt, recalc, false);
    document.addEventListener("DOMContentLoaded", recalc, false);
}