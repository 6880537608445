import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue')
  },
  {
    path: '/stkp',
    name: 'Stkp',
    component: () => import('@/views/stkp/Stkp.vue')
  },
  {
    path: '/list',
    name: 'List',
    component: () => import('@/views/list/List.vue')
  },
  {
    path: '/content',
    name: 'Content',
    component: () => import('@/views/content/Content.vue')
  },
  {
    path: '/gzdt',
    name: 'Gzdt',
    component: () => import('@/views/gzdt/Gzdt.vue')
  },
  {
    path: '/zxss',
    name: 'Zxss',
    component: () => import('@/views/zxss/Zxss.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
